// This is the template for a single sign
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import ReactMarkdown from "react-markdown";
import { Seo } from "../components/seo";

// ------------> Credits page <------------ //
const CreditsPage = (props) => {
  const credits = props.data.strapiCreditsPage;

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <div className="text-white text-md mb-[1.3em]">Credits:</div>
      <div>
        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <p
                className="text-white w-full md:w-3/4 lg:w-4/6 lg:text-md mb-[1.3em] last:mb-[2.6em]"
                {...props}
              />
            ),
          }}
        >
          {credits.creditPageBlurb.data.creditPageBlurb}
        </ReactMarkdown>
      </div>
      <div className="flex flex-row flex-wrap items-center">
        {credits.addLogo.map((logo, index) => {
          const logoImage = getImage(
            logo.logoImg.localFile.childImageSharp.gatsbyImageData
          );

          return (
            <div className="h-fit mr-[2.6em] mb-[1.3em]" key={index}>
              <a
                href={`${logo.logoLink}`}
                target="_blank"
                rel="noopener noreferrer"
                className={``}
              >
                <GatsbyImage
                  image={logoImage}
                  alt={logo.logoName}
                  className=""
                  imgStyle=""
                />
              </a>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query CreditsQuery {
    strapiCreditsPage {
      addLogo {
        logoImg {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                height: 25
                transformOptions: { fit: COVER }
                formats: NO_CHANGE
                quality: 100
                layout: CONSTRAINED
              )
            }
          }
        }
        logoName
        logoLink
      }
      creditPageBlurb {
        data {
          creditPageBlurb
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default CreditsPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Credits"}
    description={"This project was made possible by the support of..."}
    keywords={"specualtive design, art instalation"}
  />
);
